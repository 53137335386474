import React from 'react';
import './PrivacyPolicy.css'; // Asegúrate de crear este archivo CSS

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h2>Políticas de Privacidad y datos personales</h2>
      <p>
        Este documento de Recolección de Datos Personales y Políticas de Privacidad compone las políticas de uso de www.calu-mkt.com y sus redes sociales vinculadas: @calu_mkt -Instagram-, CALU–MKT Digital -Facebook-, @calumktdigital -Tiktok-, calu-mktdigital -Linkedin-, Calu -Spotify- y @CaluMktdigital -Youtube-. Configura un contrato entre usuario y titular de la base de datos y un compromiso a cumplir las condiciones aquí establecidas, así como regula los derechos y protecciones legales de ambas partes. Los datos de carácter personal que se proporcionen tanto en el eventual momento de registrarse como usuario y/o que se faciliten a consecuencia de la utilización de nuestra página Web y/o los servicios ofrecidos por éstas, serán denominados los “Datos de Carácter Personal”, y recogidos en un BANCO DE DATOS.
      </p>
      <p>
        <strong>Titularidad:</strong> El titular del banco de datos es la Srta. Maria Florencia Cattarelli con domicilio en Carlos Tejedor 248, Temperley, Lomas de Zamora, Buenos Aires, Argentina.
      </p>
      <p>
        <strong>Datos:</strong> Dichos datos pueden componerse de nombre y apellido, número de teléfono y cuenta de e-mail. a). Obligatorios: La cumplimentación del formulario de registro es obligatoria para acceder y disfrutar de los servicios ofrecidos en la web, en ella se solicita: nombre completo y dirección de e-mail. Sin perjuicio de la navegación libre por la misma. La suscripción a boletines informativos forma parte de esta obligación y al prestar consentimiento a esta política, el usuario presta consentimiento a brindar la información aquí descripta con el fin señalado en el presente documento. El no facilitar los datos personales solicitados o el no aceptar la política de protección de datos supone la imposibilidad de suscribirse, registrarse o participar en cualquiera de las promociones y servicios en las que se soliciten dichos datos carácter personal. b). Optativos: Es optativo el ingreso de número de teléfono.
      </p>
      <p>
        <strong>Veracidad:</strong> Los datos deben ser exactos. El usuario tiene un compromiso de veracidad al brindarlos. Bajo su exclusiva responsabilidad se compromete a aportar datos reales siendo el único responsable de la veracidad y corrección de los datos incluidos. La web se exonera de cualquier responsabilidad al respecto. No podrán tener más de una cuenta/perfil asociado a su persona.
      </p>
      <p>
        <strong>Confidencialidad:</strong> Cualquier información, dato y/o documentación comercial, publicitaria, financiera, técnica, contable, contenido y derechos de propiedad intelectual y demás información entregada y/o suministrada por cualquier medio por el titular de la web al Usuario en virtud del presente y/o sus comunicaciones y servicios, tendrá el carácter de confidencial. En consecuencia, el usuario no podrá copiarla, divulgarla a terceros o explotarla con fines comerciales o privados. Así mismo, el Usuario deberá utilizar la máxima diligencia y la buena fe para preservar y mantener como confidencial la información que ha recibido del titular.
      </p>
      <p>
        <strong>Características y finalidad del archivo:</strong> Los datos se recaban con la finalidad de brindar respuesta a consultas y solicitudes del servicio de Marketing digital ofrecido por la titular, emitir boletines informativos vía e-mail y efectuar campañas de e-mail marketing.
      </p>
      <p>
        <strong>Naturaleza de los datos personales contenidos en cada archivo:</strong> La base solo recabará datos personales, no sensibles. Los datos obligatorios y optativos son señalados previamente (punto 3), así como la autorización que debe brindar el usuario para garantizar al titular el acceso de los mismos.
      </p>
      <p>
        <strong>Forma de recolección y actualización de datos:</strong> Los datos serán recolectados a través de un formulario digital inserto en la página web y redes sociales de la titular (@calu.mktdigital -Instagram-, CALU–MKT Digital -Facebook-, @calumktdigital -tiktok-, calu-mktdigital -linkedin-, Calu -Spotify-, @CaluMktdigital -youtube-) que debe completarse con campos obligatorios (nombre y e-mail), y optativos (comentario o mensaje y número de teléfono). También es un requisito obligatorio ingresar el e-mail para descargar archivos de la página web.
      </p>
      <p>
        <strong>Destino de los datos y personas físicas o de existencia ideal a las que pueden ser transmitidos:</strong> La plataforma que almacena los datos requeridos es Getform y la única persona que accede a esa plataforma por esta base de datos es la titular (punto 2).
      </p>
      <p>
        <strong>Modo de interrelacionar la información registrada:</strong> Desde la plataforma contratada se redirigen los datos a una cuenta de mail seleccionada por la titular para su recopilación.
      </p>
      <p>
        <strong>Medios utilizados para garantizar la seguridad de los datos, debiendo detallar la categoría de personas con acceso al tratamiento de la información:</strong> Se aplican medios automatizados de seguridad como plataforma de recolección verificada, firewall JWT (JSON Web Token), sitio seguro y alertas de ingreso. Solamente la titular de la base de datos accede a los mismos.
      </p>
      <p>
        <strong>Tiempo de conservación de los datos:</strong> Los datos se conservarán en la base por entre 2 y 5 años, dependiendo de la duración de las campañas de marketing activas, tiempo luego de lo cual será dada de baja o exportada a una nueva versión.
      </p>
      <p>
        <strong>Forma y condiciones en que las personas pueden acceder a los datos referidos a ellas y los procedimientos a realizar para la rectificación o actualización de los datos:</strong> El usuario conserva la posibilidad de ejercer los derechos de acceso, rectificación y supresión de los datos. En los supuestos contemplados en el presente artículo, el titular de los datos podrá ejercer el derecho de acceso sin cargo alguno. El titular podrá en cualquier momento solicitar el retiro o bloqueo de su nombre de los bancos de datos a los que se refiere el presente artículo a través de enviar un mail a contacto@calu-mkt.com. Si quiere rectificar sus datos deberá ingresarlos a través del formulario que figura en la web y aceptando la política de privacidad y datos personales nuevamente dentro de los 5 días hábiles de solicitar la supresión o modificación de los datos anteriores. El usuario puede darse de baja de la lista eligiendo la opción de “desuscripcion” que acompaña cada mail enviado por la titular.
      </p>
      <p>
        <strong>Obligación de confidencialidad del usuario:</strong> Cualquier información, dato y/o documentación comercial, publicitaria, financiera, técnica, contable y demás información entregada y/o suministrada por cualquier medio por el TITULAR de la web al Usuario en virtud del presente, tendrá el carácter de confidencial. En consecuencia, el Usuario no podrá copiarla, divulgarla a terceros o explotarla con fines comerciales o privados. Así mismo, el Usuario deberá utilizar la máxima diligencia y la buena fe para preservar y mantener como confidencial la información que ha recibido de EL TITULAR a través de su suscripción a los servicios o campañas de e-mail marketing de la titular.
      </p>
      <p>
        <strong>Exclusión de responsabilidad:</strong> El titular no es responsable de las opiniones o comentarios realizados por el usuario quien se obliga a vincularse en la web con una conducta que respete la normativa vigente y los derechos de terceros. Se encuentra prohibido el “SPAM”, mensajes con fines comerciales, o mailings masivos, falsos, engañosos. El usuario responderá frente a la titular o frente a cualquier tercero, de cualesquiera daños y/o perjuicios que pudieran causarse como consecuencia del incumplimiento de estas obligaciones.
      </p>
      <p>
        <strong>Incumplimiento:</strong> “LA AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su carácter de Órgano de Control de la Ley N° 25.326, tiene la atribución de atender las denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por incumplimiento de las normas vigentes en materia de protección de datos personales”.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
